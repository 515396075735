import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Table,
  Alert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';
import { phoneMask, maskCPF, maskDate } from 'utils/mask';
import Address from './component/address';
import BankDigitation from './component/bankDigitation';
import {
  digitationService,
  getDigitationServiceByUuid,
  getDigitationHistoryServiceByUuid,
  showLinkFormalize,
} from 'services/Digitation/DigitationService';
import { useParams } from 'react-router-dom';
import { cepMask } from 'utils/mask';
import { moneyMask } from 'utils/mask';
import { getAllNoteByClientId } from 'services/User/Note';

const DigitationINSS = () => {
  let { id } = useParams();
  const [digitationHistory, setDigitationHistory] = useState([]);
  const [clientNote, setClientNote] = useState([]);
  const [digitation, setDigitation] = useState({
    cpf: null,
    name: null,
    birthDate: null,
    phone: null,
    email: null,
    registry: null,
    documentRG: null,
    dateEmissionRG: null,
    organEmissionRg: null,
    stateEmissionRg: null,
    sex: null,
    stateCivil: null,
    nameMother: null,
    cep: null,
    address: null,
    numberAddress: null,
    district: null,
    city: null,
    state: null,
    bank: null,
    agency: null,
    numberAccount: null,
    typeAccount: null,
    pix: null,
    marginIncrease: 0,
  });

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    digitationService({
      id: digitation.id,
      uuid: digitation.uuid,
      name: digitation.name,
      cpf: digitation.cpf,
      birthDate: digitation.birthDate,
      email: digitation.email,
      whatsappNumber: digitation.phone,
      documentRG: digitation.documentRG,
      dateEmissionRG: digitation.dateEmissionRG,
      organRg: digitation.organEmissionRg,
      stateRG: digitation.stateEmissionRg,
      sex: digitation.sex,
      cep: digitation.cep,
      address: digitation.address,
      district: digitation.district,
      number: digitation.numberAddress,
      city: digitation.city,
      state: digitation.state,
      registry: digitation.registry,
      pix: digitation.pix,
      bank: digitation.bank,
      numberAccount: digitation.numberAccount,
      agency: digitation.agency,
      typeAccount: digitation.typeAccount,
      simulationInss: digitation.simulationInss,
      febrabanId: digitation.febrabanId,
      nameMother: digitation.nameMother,
      stateCivil: digitation.stateCivil,
      observation: digitation.observation,
      marginIncrease: digitation.marginIncrease,
      bankId: digitation.bankId,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Successo',
          text: 'Digitação salva com sucesso!',
        });
        handleShowObservations(digitation.clientId);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetDigitation = (uuid) => {
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getDigitationServiceByUuid(uuid).then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setDigitation({
        id: result.id,
        name: result.name,
        uuid: result.uuid,
        installment: result.installment,
        valueInstallment: result.valueInstallment,
        valueLiberty: result.valueLiberty,
        cpf: maskCPF(result.documentCPF),
        birthDate: result.birthDate,
        email: result.email,
        phone: phoneMask(result.whatsappNumber),
        documentRG: result.documentRG,
        dateEmissionRG: result.dateEmissionRG,
        organEmissionRg: result.emissionRG,
        stateEmissionRg: result.stateRG,
        sex: result.sex,
        cep: result.CEP ? cepMask(result.CEP) : '',
        address: result.address,
        district: result.district,
        numberAddress: result.number,
        city: result.city,
        state: result.state,
        registry: result.registry,
        pix: result.pix,
        bank: result.bank,
        numberAccount: result.numberAccount,
        agency: result.agency,
        typeAccount: result.typeAccount,
        simulationInss: result.simulationInss,
        febrabanId: result.febrabanId,
        nameMother: result.nameMother,
        stateCivil: result.stateCivil,
        complement: result.complement,
        clientId: result.clientId,
        indicationId: result.indicationId,
        indication: result.indication,
        serviceId: result.serviceId,
        statusAttendantId: result.statusAttendantId,
      });

      handleShowObservations(result.clientId);
    });
  };

  const handleShowHistory = (id) => {
    getDigitationHistoryServiceByUuid(id).then((res) => {
      setDigitationHistory(res.data.resultSet);
    });
  };

  const handleShowObservations = (clientId) => {
    getAllNoteByClientId(clientId).then((res) => {
      setClientNote(res.data.resultSet);
    });
  };

  const getLinkFormalize = async (numberProposal, febrabanId) => {
    Swal.fire({
      title: 'Buscando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showLinkFormalize(numberProposal, febrabanId)
      .then((res) => {
        const link = res.data.resultSet;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `<input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (!digitation.cpf && id) {
      handleGetDigitation(id);
      handleShowHistory(id);
    }
  }, [digitation]);

  if (id && !digitation?.id) {
    return (
      <>
        <Alert color="secondary">Registro não identificado</Alert>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                      <i className="fas fa-file-alt"></i>{' '}
                      {digitation.id > 0 ? 'Editar' : 'Cadastrar'} Proposta
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {Number(digitation.serviceId) === 6 && (
                  <div className="p-3 bg-secondary mb-4">
                    <h4>Simulação</h4>
                    <Row>
                      <Col md="4">
                        <Card className="shadow">
                          <CardBody>
                            <h5 className="m-0">Parcelas</h5>
                            <p className="m-0">{digitation.installment}</p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow">
                          <CardBody>
                            <h5 className="m-0">Valor Parcela</h5>
                            <p className="m-0">
                              R${' '}
                              {moneyMask(
                                Number(digitation.valueInstallment).toFixed(2)
                              )}
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow">
                          <CardBody>
                            <h5 className="m-0">Valor Client</h5>
                            <p className="m-0">
                              R${' '}
                              {moneyMask(
                                Number(digitation.valueLiberty).toFixed(2)
                              )}
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>CPF</Label>
                      <Input
                        name="cpf"
                        id="cpf"
                        value={digitation.cpf}
                        placeholder="Informar CPF do cliente"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            cpf: maskCPF(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Nome</Label>
                      <Input
                        name="name"
                        id="name"
                        value={digitation.name}
                        placeholder="Digitar o nome do cliente"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            name: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Dt. nascimento</Label>
                      <Input
                        name="birthDate"
                        id="birthDate"
                        value={digitation.birthDate}
                        placeholder="Informar data de nascimento"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            birthDate: maskDate(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>WhatsApp</Label>
                      <Input
                        name="whatsappNumber"
                        id="whatsappNumber"
                        value={digitation.phone}
                        placeholder="Informar WhatsApp"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            phone: phoneMask(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>E-mail</Label>
                      <Input
                        name="email"
                        id="email"
                        value={digitation.email}
                        placeholder="Informar e-mail do cliente"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            email: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Matricula</Label>
                      <Input
                        name="registry"
                        id="registry"
                        value={digitation.registry}
                        placeholder="RG do cliente"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            registry: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>RG</Label>
                      <Input
                        name="documentRG"
                        id="documentRG"
                        value={digitation.documentRG}
                        placeholder="RG do cliente"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            documentRG: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Dt Emissão</Label>
                      <Input
                        name="dateEmissionRG"
                        id="dateEmissionRG"
                        value={digitation.dateEmissionRG}
                        placeholder="--/--/----"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            dateEmissionRG: maskDate(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Orgão Emissor</Label>
                      <Input
                        name="organEmissionRg"
                        id="organEmissionRg"
                        value={digitation.organEmissionRg}
                        placeholder=""
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            organEmissionRg: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Estado Emissor</Label>
                      <select
                        className="form-control form-control-md"
                        name="stateEmissionRg"
                        id="stateEmissionRg"
                        value={digitation.stateEmissionRg}
                        placeholder="Informar WhatsApp"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            stateEmissionRg: ev.target.value,
                          });
                        }}
                      >
                        <option name="" disabled selected>
                          Selecione
                        </option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Sexo</Label>
                      <select
                        className="form-control form-control-md"
                        name="sex"
                        id="sex"
                        value={digitation.sex}
                        placeholder=""
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            sex: ev.target.value,
                          });
                        }}
                      >
                        <option name="" disabled selected>
                          Selecione
                        </option>
                        <option name="M">Masculino</option>
                        <option name="F">Feminino</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Estado Civil</Label>
                      <select
                        className="form-control form-control-md"
                        name="stateCivil"
                        id="stateCivil"
                        value={digitation.stateCivil}
                        placeholder=""
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            stateCivil: ev.target.value,
                          });
                        }}
                      >
                        <option name="" disabled selected>
                          Selecione
                        </option>
                        <option name="SOLTEIRO">Solteiro</option>
                        <option name="CASADO">Casado</option>
                        <option name="DIVORCIADO">Divorciado</option>
                        <option name="VIUVO">Viúvo</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="9">
                    <FormGroup>
                      <Label>Nome da mãe</Label>
                      <Input
                        name="nameMother"
                        id="nameMother"
                        value={digitation.nameMother}
                        placeholder="Informar nome da mãe"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            nameMother: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Address
                  setDigitation={setDigitation}
                  digitation={digitation}
                />
                <BankDigitation
                  setDigitation={setDigitation}
                  digitation={digitation}
                  showPix={false}
                  readonly={{
                    bank: true,
                    agency: true,
                  }}
                />
                <Alert color="secondary" className="mt-5 border">
                  <h2 className="m-0">Anotações / Observação</h2>
                </Alert>
                <textarea
                  className="form-control"
                  placeholder="Anotações"
                  value={digitation.observation}
                  onChange={(ev) => {
                    setDigitation({
                      ...digitation,
                      observation: ev.target.value,
                    });
                  }}
                />

                {digitation.indicationId > 0 ? (
                  <>
                    <Alert color="secondary" className="mt-5 border">
                      <h2 className="m-0">INDICAÇÃO</h2>
                    </Alert>
                    <Alert color="warning">
                      Esta digitação possúi uma indicação
                    </Alert>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Nome</Label>
                          <Input
                            value={digitation.indication.name}
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>CÓDIGO</Label>
                          <Input
                            value={digitation.indication.uuid}
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </CardBody>
              {![5, 11].includes(Number(digitation.statusAttendantId)) && (
                <CardFooter>
                  <Button color="success" onClick={handleSubmit}>
                    Salvar Digitação
                  </Button>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
        {digitationHistory.length > 0 ? (
          <Card className="mt-5">
            <CardHeader>
              <h4 className="m-0">HISTÓRICO - CONTRATOS DO CLIENTE</h4>
            </CardHeader>
            <CardBody>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>BANCO</th>
                    <th>Nº PROPOSTA</th>
                    <th>VALORES</th>
                    <th>STATUS</th>
                    <th>DATA CADASTRO</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {digitationHistory.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <p>{data.bank}</p>
                      </td>
                      <td>
                        <p>{data.numberProposal}</p>
                      </td>
                      <td>
                        <p>
                          <strong>VALOR CONTRATO:</strong>
                          {` `}
                          R$ {moneyMask(data.valueFinally)}
                        </p>
                        <p>
                          <strong>VALOR VALOR LIBERADO:</strong>
                          {` `}
                          R$ {moneyMask(data.valueLiberty)}
                        </p>
                      </td>
                      <td>
                        <p>
                          {data.situation} - {data.activity}
                        </p>
                      </td>
                      <td>
                        <p>{data.createdAt}</p>
                      </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            <DropdownItem
                              href={`/admin/proposal-details/${
                                data.proposalId ?? 0
                              }`}
                              target="_blank"
                            >
                              <i className="fas fa-edit" /> Detalhes do Contrato
                            </DropdownItem>
                            {(data.febrabanId == '626' ||
                              data.febrabanId == '149' ||
                              data.febrabanId == '623' ||
                              data.febrabanId == '074') &&
                            data.proposalId > 0 ? (
                              <>
                                <DropdownItem
                                  onClick={() => {
                                    getLinkFormalize(
                                      data.numberProposal,
                                      data.febrabanId
                                    );
                                  }}
                                >
                                  <i className="fas fa-edit" /> Link de
                                  Formalização
                                </DropdownItem>
                              </>
                            ) : (
                              <></>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        ) : (
          <></>
        )}

        {clientNote.length > 0 ? (
          <Card className="mt-5">
            <CardHeader>
              <h4 className="m-0">ANOTAÇÕES / OBSERVAÇÕES</h4>
            </CardHeader>
            <CardBody style={{ maxHeight: '600px', overflow: 'auto' }}>
              {clientNote.map((data, index) => (
                <>
                  <div key={index} className="border p-4 rounded mb-2 mt-2">
                    <h3>
                      {data.name} - {data.createdAt}
                    </h3>
                    <p>{data.message}</p>
                  </div>
                </>
              ))}
            </CardBody>
          </Card>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default DigitationINSS;

import api from 'services/api';

export const saveProposalCrefisaService = async (
  serviceId,
  formData,
  onUploadProgress = () => {}
) => {
  return await api.post(`v3/personal-credit/crefisa/${serviceId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const showClientHasPermissionService = async (cpf) => {
  return await api.get(
    `v3/personal-credit/crefisa/client-has-permission/${cpf}`
  );
};

export const historyProposalCrefisaService = async (id) => {
  return await api.get(`v3/personal-credit/crefisa/history/${id}`);
};

import { useEffect } from 'react';
import { useState } from 'react';
import { maskCPF } from 'utils/mask';

const {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Row,
  Col,
} = require('reactstrap');

const UserDigitationFactaConfigComponent = ({
  bank,
  handleSubmit = () => {},
}) => {
  const [userDigitation, setUserDigitation] = useState({
    documentUser: null,
    bankId: null,
    idBankUser: null,
  });

  useEffect(() => {
    setUserDigitation({
      documentUser: bank.userDigitation.documentUser,
      bankId: bank.id,
      idBankUser: bank.userDigitation.idBankUser,
    });
  }, []);

  if (bank.febraban !== '149') return <></>;

  return (
    <>
      <Card className="shadow">
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>CPF digitador</Label>
                <Input
                  type="text"
                  placeholder="Ex: 000.000.000-00"
                  value={userDigitation.documentUser ?? ''}
                  onChange={(ev) => {
                    setUserDigitation({
                      ...userDigitation,
                      documentUser: maskCPF(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Codigo usuário digitador</Label>
                <Input
                  type="text"
                  value={userDigitation.idBankUser ?? ''}
                  placeholder="Ex: 123456"
                  onChange={(ev) => {
                    setUserDigitation({
                      ...userDigitation,
                      idBankUser: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            onClick={() => {
              handleSubmit(userDigitation);
            }}
          >
            SALVAR
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default UserDigitationFactaConfigComponent;

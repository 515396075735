import { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { listAllTableService } from 'services/Comission/TableComissionService';
import { moneyMask } from 'utils/mask';
const ConditionSimulationFGTSPrataDigital = ({
  bankId,
  setTableSelected,
  valueSolicited,
  setValueSolicited = () => {},
}) => {
  const [table, setTable] = useState([]);

  const handleListTable = async () => {
    const result = await listAllTableService('329-3');
    if (!result) return;
    setTable(result.resultSet);
  };

  useEffect(() => {
    if (bankId !== 14) return;
    handleListTable();
  }, []);

  if (bankId !== 14) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>Tabela(s)</Label>
            <Select
              isMulti
              className="basic-single"
              classNamePrefix="select"
              isClearable
              placeholder="Selecione..."
              options={table.map((res) => {
                return {
                  value: res.tableId,
                  label: res.tableName,
                };
              })}
              onChange={(res) => {
                const values = res.map((res) => {
                  return res.value;
                });
                setTableSelected(values);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>Valor solicitado</Label>
            <Input
              type="text"
              value={valueSolicited}
              onChange={(ev) => {
                setValueSolicited(moneyMask(ev.target.value));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ConditionSimulationFGTSPrataDigital;

import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import ChartBar from 'views/Panel/component/ChartBar';
import { useEffect } from 'react';
import { reportDashboardIndicationService } from 'services/Promoter/ReportIndicationService';
import { moneyMask } from 'utils/mask';
import { generateColorRegex } from 'utils/ColorRegexUtil';
import Swal from 'sweetalert2';

const DashboardIndicationView = () => {
  const currentMonth = new Date().getMonth() + 1;
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    febrabanId: '',
    viewBy: 'production',
    limit: 10,
    month: currentMonth.toString(),
  });
  const [prodution, setProdution] = useState({
    liqdProdution: 'R$ 0,00',
    ticket: 'R$ 0,00',
    qtdContracts: '0',
  });

  const graphDefault = {
    labels: [],
    datasets: [
      {
        label: 'Relatório',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };
  const [graphProductionIndication, setGraphProductionIndication] =
    useState(graphDefault);
  const [graphQtdIndication, setGraphQtdIndication] = useState(graphDefault);

  const handleList = async () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const result = await reportDashboardIndicationService(filter);

    if (!result) return;

    Swal.close();

    if (!result?.resultSet) return;

    const resultSet = result?.resultSet;

    if (resultSet?.resume) {
      setProdution({
        liqdProdution: `R$ ${moneyMask(resultSet?.resume?.total ?? '0.00')}`,
        ticket: `R$ ${moneyMask(resultSet?.resume?.avg ?? '0.00')}`,
        qtdContracts: resultSet?.resume?.qtd ?? 0,
      });
    }

    const colors = generateColorRegex(filter?.limit ?? 10);

    if (resultSet?.total) {
      setGraphProductionIndication({
        labels: resultSet?.total.map((result) => {
          return result.name;
        }),
        datasets: [
          {
            label: 'Produção',
            data: resultSet?.total.map((result) => {
              return result.total;
            }),
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      });
    }

    if (resultSet?.qtd) {
      setGraphQtdIndication({
        labels: resultSet?.qtd.map((result) => {
          return result.name;
        }),
        datasets: [
          {
            label: 'Produção',
            data: resultSet?.qtd.map((result) => {
              return result.qtd;
            }),
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      });
    }
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="Dashboard Indicação / Vendedor" />
      <Container className="mt--9" fluid>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2>Dashboard Indicação / Vendedor - Filtro</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Mês</Label>
                  <select
                    id="month"
                    name="month"
                    className="form-control"
                    value={filter.month}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        month: ev.target.value,
                      });
                    }}
                  >
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Financeira</Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    value={filter.febrabanId}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    <option value="626">C6 Consignado</option>
                    <option value="149">FACTA Financeira</option>
                    <option value="074">SAFRA</option>
                    <option value="623">PAN</option>
                    <option value="389">BANCO MERCANTIL</option>
                    <option value="329">iCred</option>
                    <option value="465">Quero+Crédito</option>
                    <option value="318">BMG</option>
                    <option value="329-2">SABEMI</option>
                    <option value="324">LOTUS MAIS</option>
                    <option value="457">457 - UY3</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Visualizar por</Label>
                  <select
                    id="viewBy"
                    name="viewBy"
                    className="form-control"
                    value={filter.viewBy}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        viewBy: ev.target.value,
                      });
                    }}
                  >
                    <option value="production">Produção</option>
                    <option value="comission">Comissionamento </option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Quantidade de Registros</Label>
                  <Input
                    id="qtdRegister"
                    name="qtdRegister"
                    className="form-control"
                    type="number"
                    value={filter.limit}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        limit: ev.target.value,
                      });
                    }}
                    max={500}
                    maxLength={500}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleList}>
              <i className="fas fa-filter"></i> Filtrar
            </Button>
          </CardFooter>
        </Card>

        <Card className="mb-4">
          <CardHeader>
            <h3>Produção Geral</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Card className="shadow card-status mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Produção Liquido
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {prodution.liqdProdution}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fa fa-money-bill" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="shadow card-status mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          TICKET MÉDIO
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {prodution.ticket}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fa fa-chart-line"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="shadow card-status mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          QTD CONTRATOS
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {prodution.qtdContracts}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fa fa-light fa-file-contract"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col xl="12" className="mt-4">
            <ChartBar
              title="Produção"
              subtitle="Mês"
              data={graphProductionIndication}
              isMoney={true}
            />
          </Col>
          <Col xl="12" className="mt-4">
            <ChartBar
              title="Quantidade"
              subtitle="Mês"
              data={graphQtdIndication}
            />
          </Col>
          {/* <Col xl="12" className="mt-4">
            <ChartBar
              title="Produção total"
              subtitle="ULTIMOS 30 DIAS"
              data={graphProductionIndication}
            />
          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default DashboardIndicationView;

import { Alert, Badge, Col, FormGroup, Input, Label, Row } from 'reactstrap';

const BankDigitation = ({
  setDigitation,
  digitation,
  showPix,
  readonly = { bank: false, agency: false },
}) => {
  return (
    <>
      <Alert color="secondary" className="mt-5 border">
        <h2 className="m-0">Dados Bancários</h2>
      </Alert>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label>Banco</Label>
            <select
              disabled={readonly?.bank === true ? true : false}
              name="bank"
              id="bank"
              value={digitation.bank}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  bank: ev.target.value,
                });
              }}
              className="form-control form-control-md"
            >
              <option value="">SELECIONE</option>
              <option value="104">CAIXA ECONOMICA FEDERAL</option>
              <option value="001">BANCO DO BRASIL S.A.</option>
              <option value="237">BANCO BRADESCO</option>
              <option value="341">BANCO ITAÚ S.A.</option>
              <option value="336">BANCO C6 S.A.</option>
              <option value="033">BANCO SANTANDER S.A.</option>
              <option value="151">BANCO NOSSA CAIXA S.A.</option>
              <option value="745">BANCO CITIBANK S.A.</option>
              <option value="041">
                BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
              </option>
              <option value="756">BANCO SICOOB</option>
              <option value="748">BANCO SICREDI</option>
              <option value="085">
                COOPERATIVA CENTRAL DE CRÉDITO - AILOS
              </option>
              <option value="399">HSBC BANK BRASIL S.A</option>
              <option value="756">
                BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
              </option>
              <option value="041">BANRISUL</option>
              <option value="212">BANCO ORIGINAL</option>
              <option value="260">NU BANK PAGAMENTOS S.A.</option>
              <option value="318">BANCO BMG S.A.</option>
              <option value="336">BANCO C6 S.A. (C6 BANK)</option>
              <option value="077">BANCO INTER S.A.</option>
              <option value="389">Banco Mercantil do Brasil S.A.</option>
            </select>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label>Agência</Label>
            <Input
              readOnly={readonly?.agency === true ? true : false}
              name="agency"
              id="agency"
              value={digitation.agency}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  agency: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>Conta com dígito</Label>
            <Input
              name="numberAccount"
              id="numberAccount"
              value={digitation.numberAccount}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  numberAccount: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>Tipo de conta</Label>
            <select
              className="form-control form-control-md"
              name="state"
              id="state"
              value={digitation.typeAccount}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  typeAccount: ev.target.value,
                });
              }}
            >
              <option name="" disabled selected>
                Selecione
              </option>
              <option value="01">Conta Corrente</option>
              <option value="02">Conta Poupança</option>
            </select>
          </FormGroup>
        </Col>
        {showPix && (
          <Col md="4">
            <FormGroup>
              <Label>
                Chave PIX <Badge color="info">Opcional</Badge>
              </Label>
              <Input
                name="pix"
                id="pix"
                value={digitation.pix}
                onChange={(ev) => {
                  setDigitation({
                    ...digitation,
                    pix: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </>
  );
};

export default BankDigitation;

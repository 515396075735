import { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import SampleModalView from 'components/Modals/SampleModalView';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { userDigitationBanksPermitedService } from 'services/User/UserDigitation';
import { saveUserDigitationService } from 'services/User/UserDigitation';
import UserDigitationIcredConfigComponent from './UserDigitationIcredConfigComponent';
import UserDigitationPrataConfigComponent from './UserDigitationPrataDigitalConfigComponent';
import UserDigitationFactaConfigComponent from './UserDigitationFactaConfigComponent';

const UserDigitationComponent = ({ userId, isOpen, setIsOpen = () => {} }) => {
  const [banks, setBanks] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState({
    name: null,
    email: null,
  });
  const handleList = (userId) => {
    if (!userId || userId <= 0) return;
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    userDigitationBanksPermitedService(userId)
      .then(({ data }) => {
        Swal.close();
        setUser(data?.resultSet?.user);
        setBanks(data?.resultSet?.banks ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = (bank) => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    console.log(bank);

    saveUserDigitationService({
      ...bank,
      userId: userId,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro atualizado com sucesso',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    setBanks([]);
    handleList(userId);
  }, [isOpen]);

  if (userId <= 0 || banks?.length <= 0) {
    return <></>;
  }
  return (
    <>
      <SampleModalView
        labelModal="Usuário digitador"
        icon="fas fa-user-tie"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={() => {
          setIsOpen();
        }}
        showBtnSubmit={false}
        size="xl"
      >
        <Card className="shadow">
          <CardBody>
            <Row>
              <Col md="6">
                <p>Nome</p>
                <h3>{user.name}</h3>
              </Col>
              <Col md="6">
                <p>E-mail</p>
                <h3>{user.email}</h3>
              </Col>
            </Row>
            <Nav className="mt-5" tabs>
              {banks.map((bank, key) => (
                <NavItem className="border-1" key={`navItem-${key}`}>
                  <NavLink 
                    className={`cursor-pointer ${
                      activeTab === key ? 'active' : null
                    }`}
                    onClick={() => {
                      setActiveTab(key);
                    }}
                  >
                    <h3>{bank.name}</h3>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab} className="p-4 border">
              {banks.map((bank, key) => (
                <TabPane tabId={key} key={`tabPane-${key}`}>
                  <UserDigitationIcredConfigComponent
                    banks={banks}
                    bank={bank}
                    key={key}
                    setBanks={setBanks}
                    handleSubmit={handleSubmit}
                  />
                  <UserDigitationPrataConfigComponent
                    banks={banks}
                    bank={bank}
                    key={key}
                    setBanks={setBanks}
                    handleSubmit={handleSubmit}
                  />
                  <UserDigitationFactaConfigComponent
                    banks={banks}
                    bank={bank}
                    key={key}
                    setBanks={setBanks}
                    handleSubmit={handleSubmit}
                  />
                </TabPane>
              ))}
            </TabContent>
          </CardBody>
        </Card>
      </SampleModalView>
    </>
  );
};

export default UserDigitationComponent;

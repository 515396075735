import Chart from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { moneyMask } from 'utils/mask';
import { chartOptions, parseOptions } from 'variables/charts.js';

const ChartBar = (
  props = {
    title: '',
    subtitle: '',
    data: [],
    background: 'bg-white',
    isMoney: false,
  }
) => {
  if (props.data.length <= 0 || props.data.labels.length <= 0) {
    return <></>;
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const valueDisplayPlugin = {
    id: 'valueDisplayPlugin',
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        meta.data.forEach((bar, index) => {
          let value = dataset.data[index];
          console.log(value);
          const { x, y } = bar.tooltipPosition();

          if (props?.isMoney === true) {
            value = 'R$ ' + moneyMask(value);
          }

          ctx.save();
          ctx.font = '11px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(value, x, y - 5);
          ctx.restore();
        });
      });
    },
  };

  return (
    <>
      <Card className={props.background}>
        <CardHeader className="bg-transparent">
          <h2>
            <small>{props.subtitle}</small> / {props.title}
          </h2>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Bar
              data={props.data}
              options={{
                responsive: true,
                layout: {
                  padding: 15,
                },
                tooltips: {
                  enabled: true,
                  callbacks: {
                    label: function (item, data) {
                      var label = data.datasets[item.datasetIndex].label || '';
                      var yLabel = item.yLabel;
                      var content = '';

                      if (data.datasets.length > 1) {
                        content += label;
                      }

                      content += yLabel;

                      if (props?.isMoney === true) {
                        content = 'R$ ' + moneyMask(content);
                      }

                      return content;
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                  },
                  tooltip: {
                    enabled: true, // Mantém o tooltip habilitado
                  },
                },
                scales: {
                  xAxes: 10,
                },
              }}
              plugins={[valueDisplayPlugin]}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ChartBar;

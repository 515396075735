import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllStatusAttendant } from 'services/Digitation/DigitationService';
import { listDigitationQueueService } from 'services/Digitation/DigitationService';
import Swal from 'sweetalert2';
import { updateDigitationStatusAttendant } from 'services/Digitation/DigitationService';
import ModalClientDocumentComponent from '../../Client/Component/ModalClientDocumentComponent';
import { maskCPF } from 'utils/mask';
import { useHistory } from 'react-router-dom';

const ReportDigitationQueueComponent = ({
  serviceId = null,
  buttons = null,
}) => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [statusAttendant, setStatusAttendant] = useState([]);
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [showModalDocuments, setShowModalDocuments] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [filter, setFilter] = useState({
    pageActual: 0,
    dateStart: null,
    dateEnd: null,
    statusAttendantId: null,
    cpf: null,
  });

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleStatusAttendant = () => {
    getAllStatusAttendant()
      .then((res) => {
        setStatusAttendant(res.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleList = (btnSearch = 0) => {
    setShowBtnMoreRegisters(false);
    Swal.fire({
      title: 'Carregando listagem',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const newFilter = filter;

    if (btnSearch > 0) {
      newFilter.pageActual = 0;
      setFilter(newFilter);
    }

    listDigitationQueueService(serviceId, newFilter)
      .then(({ data }) => {
        Swal.close();
        setFilter({ ...filter, pageActual: data.resultSet.pagination });
        setList(
          filter.pageActual === 0
            ? data.resultSet.data
            : [...list, ...data.resultSet.data]
        );

        if (data.resultSet.data.length > 0) {
          setShowBtnMoreRegisters(true);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleChangeStatus = (digitationId, statusId, key) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja alterar o status da digitação?',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, continuar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Alterarando status',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      updateDigitationStatusAttendant({
        digitationId: digitationId,
        statusAttendantId: statusId,
      })
        .then(() => {
          const newList = [...list];
          newList[key].statusAttendantId = statusId;
          setList(newList);

          Swal.close();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const openModalDocuments = () => {
    setShowModalDocuments(true);
  };

  const closeModal = () => {
    setClientId(null);
    setShowModalDocuments(false);
  };

  useEffect(() => {
    handleStatusAttendant();
    handleList(1);
  }, []);

  return (
    <>
      <Header titlePage="Esteira de digitação de propostas" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="m-0">
              <i className="fas fa-list-alt" /> ESTEIRA DE DIGITAÇÃO
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      value={filter.dateStart}
                      onChange={handleChange}
                    />
                    <Input
                      className="border"
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      value={filter.dateEnd}
                      style={{ paddingLeft: '12px' }}
                      onChange={handleChange}
                    />

                    <select
                      className="form-control border pl-2"
                      id="typeDate"
                      name="typeDate"
                    >
                      <option value="createdAt">DATA DE CADASTRO</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>CPF do Cliente</Label>
                  <Input
                    id="cpf"
                    name="cpf"
                    type="text"
                    placeholder="000.000.000-00"
                    value={filter.cpf}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nº Digitação</Label>
                  <Input
                    id="uuid"
                    name="uuid"
                    type="text"
                    value={filter.uuid}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Status Esteira</Label>
                  <select
                    className="form-control"
                    name="statusAttendantId"
                    onChange={handleChange}
                  >
                    <option value="">SELECIONE...</option>

                    {statusAttendant.map((result, key) => (
                      <option key={key} value={result.id}>
                        {result.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              onClick={() => {
                handleList(1);
              }}
            >
              <i className="fas fa-filter"></i> Buscar
            </Button>
            {buttons}
          </CardFooter>
        </Card>

        {list.length <= 0 && (
          <Alert color="secondary" className="text-center">
            Não foram identificados registros
          </Alert>
        )}

        {list.length > 0 && (
          <Card className="shadow mt-2">
            <Table responsive hover>
              <thead>
                <tr className="bg-secondary">
                  <th>DIGITAÇÃO</th>
                  <th>CLIENTE</th>
                  <th>STATUS</th>
                  <th>OPÇÕES</th>
                </tr>
              </thead>
              <tbody>
                {list.map((register, key) => (
                  <>
                    {Number(register.statusAttendantId) === 7 && (
                      <tr key={`err-${key}`} className="border-0">
                        <td colSpan="5">
                          <Alert
                            color="secondary"
                            className="m-0 border-bottom-0 text-warning"
                          >
                            <i className="fas fa-times"></i> {register.error}
                          </Alert>
                        </td>
                      </tr>
                    )}
                    <tr key={key}>
                      <td className="align-middle border-top-0">
                        <p className="m-0 font-weight-bold">
                          <Badge color="success" className="font-14 mb-2">
                            {register.service}
                          </Badge>
                        </p>
                        <p className="m-0 font-weight-bold">{register.uuid}</p>
                        <p className="m-0">
                          <strong>Banco:</strong> {register.bankName}
                        </p>
                        <p className="m-0">
                          <strong>Parcelas:</strong> {register.installment}
                        </p>
                        <p className="m-0">
                          <strong>Valor Cliente:</strong> R${' '}
                          {register.valueLiberty}
                        </p>
                        <p className="m-0">
                          <strong>Data cadastro:</strong> {register.createdAt}
                        </p>
                      </td>
                      <td className="align-middle border-top-0">
                        <p className="m-0 font-weight-bold font-18">
                          {maskCPF(register.documentCPF)}
                        </p>
                        <p className="m-0">
                          <strong>Nome:</strong> {register.name}
                        </p>
                        <p className="m-0">
                          <strong>Data Nascimento:</strong> {register.birthDate}
                        </p>
                        <p className="m-0">
                          <strong>WhatsApp:</strong> {register.whatsappNumber}
                        </p>
                      </td>
                      <td className="align-middle border-top-0">
                        <select
                          className="form-control form-control-sm"
                          value={register.statusAttendantId}
                          onChange={(ev) => {
                            handleChangeStatus(
                              register.id,
                              ev.target.value,
                              key
                            );
                          }}
                        >
                          <option value="">SELECIONE...</option>
                          {statusAttendant.map((result, key) => (
                            <option key={key} value={result.id}>
                              {result.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="align-middle border-top-0">
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            <DropdownItem
                              onClick={() => {
                                history.push(
                                  `/admin/digitation-inss-show/${register.uuid}`
                                );
                              }}
                              target="_blank"
                              className="d-flex align-items-center"
                            >
                              <i className="fas fa-file-alt"></i> Detalhes da
                              digitação
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setClientId(register.clientId);
                                openModalDocuments(register.clientId);
                              }}
                              target="_blank"
                              className="d-flex align-items-center"
                            >
                              <i className="far fa-images"></i> Visualizar
                              documento
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
            {showBtnMoreRegisters && (
              <Button
                color="secondary"
                className="w-100 mt-2"
                onClick={() => handleList()}
              >
                Carregar mais
              </Button>
            )}
            <ModalClientDocumentComponent
              showModalDocuments={showModalDocuments}
              closeModal={closeModal}
              clientId={clientId}
            />
          </Card>
        )}
      </Container>
    </>
  );
};
export default ReportDigitationQueueComponent;

const { useState } = require("react");
const { Button, InputGroupAddon, InputGroup, Input, Label } = require("reactstrap");

const InputIncrementComponent = ({
    label = null,
    name = null,
    type = null,
    placeholder = null,
    handleChange = () => { }
}) => {
    const [inputs, setInputs] = useState([{
        name: name,
        type: type
    }]);

    console.log(inputs);

    return (<><Label>Outros</Label>
        {inputs.map((register, key) => (
            <div key={key}>
                <InputGroup size="md">
                    <Input type="file" name={`${register.name}[${key}]`} onChange={handleChange} className='form-control mb-2' />
                    {
                        (inputs.length === 1 || inputs.length - 1 === key) && (
                            <InputGroupAddon className="h-100" addonType='append'><Button color='success' onClick={() => {
                                setInputs([...inputs, {
                                    name: register.name, type: register.type
                                }])
                            }}>+</Button></InputGroupAddon>
                        )
                    }
                </InputGroup>
            </div>
        ))}</>)
}

export default InputIncrementComponent
import { Alert, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useEffect } from 'react';

const AccessBankCrefisaForm = ({ febrabanId, data, setData }) => {

  useEffect(() => {
    if (febrabanId !== '069') return;
    setData({
      ...data,
      documentUser: 'abc',
      nameUser: 'abc',
      idBankUser: 'abc',
      useFgts: 0,
      useMultBankFgts: 0,
    });
  }, []);

  if (!febrabanId || febrabanId !== '069') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas CP
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Atualização de status de proposta
        </p>
      </Alert>
      <Alert color="warning shadow">
        <p className="m-0">
          A integração da Crefisa é uma automação cujo funcionamento é
          muito próximo da experiência interna da Crefisa. No entanto, não
          se trata de uma integração oficial.
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </CardBody>
  );
};

export default AccessBankCrefisaForm;

import { useEffect } from 'react';
import { useState } from 'react';
import { historyProposalCrefisaService } from 'services/Digitation/PersonalCreditCrefisaService';
import Swal from 'sweetalert2';

const {
  default: SampleModalView,
} = require('components/Modals/SampleModalView');
const {
  Card,
  CardHeader,
  Table,
  CardBody,
  Alert,
  Badge,
} = require('reactstrap');

const ListHistoryCrefisaComponent = ({
  proposalId,
  isOpen = false,
  hideModal = () => {},
}) => {
  const [error, setError] = useState(null);
  const [list, setList] = useState([]);
  const handleList = (proposalBank) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setError(null);
    historyProposalCrefisaService(proposalBank)
      .then(({ data }) => {
        Swal.close();
        setList(data?.resultSet ?? []);

        if (!data?.resultSet || data?.resultSet?.length <= 0) {
          setError('Não possuem registros');
        }
      })
      .catch((err) => {
        Swal.close();

        setError(
          !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message
        );
      });
  };

  useEffect(() => {
    setList([]);
    setError(null);
    if (!proposalId) return;
    handleList(proposalId);
  }, [proposalId, isOpen]);

  return (
    <>
      <SampleModalView
        labelModal="Esteira Crefisa - Histórico"
        icon="fas fa-history"
        isOpen={isOpen}
        setIsOpen={hideModal}
        showBtnSubmit={false}
        size="xl"
      >
        <Card className="shadow">
          <CardHeader>Histórico</CardHeader>
          {error && (
            <CardBody>
              <Alert color="warning">{error}</Alert>
            </CardBody>
          )}
          {list.length > 0 && (
            <div className="table-responsive">
              <Table responsive bordered>
                <thead>
                  <tr className="bg-secondary">
                    <th>INCLUSÃO</th>
                    <th>STATUS</th>
                    <th>OBSERVAÇÃO</th>
                    {/* <th>ANEXOS</th> */}
                  </tr>
                </thead>
                <tbody>
                  {list.map((register, key) => (
                    <tr key={key}>
                      <td className="align-middle">
                        <p className="m-0">{register.DataCadastroHistorico}</p>
                        <p className="m-0">{register.HoraCadastroHistorico}</p>
                        <Badge color="info">
                          {register.NomeAbreviadoUsuario}
                        </Badge>
                      </td>
                      <td className="align-middle">
                        <p
                          className="m-0"
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {register.StatusEsteiraAnalise}
                        </p>
                      </td>
                      <td className="align-middle">
                        <p
                          className="m-0"
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {register.ObservacaoHistorico}
                        </p>
                      </td>
                      {/* <td></td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Card>
      </SampleModalView>
    </>
  );
};

export default ListHistoryCrefisaComponent;

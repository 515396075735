import InputFebrabanView from 'components/Form/Input/InputFebrabanView';
import Header from 'components/Headers/Header';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap';
import { insertService } from 'services/Comission/SellerComissionTableService';
import { listAllTableService } from 'services/Comission/TableComissionService';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';
import { Title } from 'views/Dashboard/styles';

const ComissionTableRegisterView = () => {
  dayjs().locale('pt-br');

  const history = useHistory();
  const [data, setData] = useState({
    serviceId: 2,
    typeCalcComission: 'PERIOD'
  });
  const [tableName, setTableName] = useState('');
  const [listTableBank, setListTableBank] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const bankRef = useRef(null);

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    insertService({
      ...data,
      name: tableName
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro salvo com sucesso!',
          showCancelButton: true,
          confirmButtonText: 'Novo Cadastro',
          cancelButtonText: 'Sair'
        }).then((result) => {
          setIsSubmitted(false);

          if (result.isConfirmed) {
            setData({
              serviceId: data.serviceId,
              febrabanId: data.febrabanId,
              nameBank: data.nameBank,
              valueComissionBank: '',
              valueComission: '',
              tableComissionBankId: '',
              typeCalcComission: 'PERIOD',
              dateStart: '',
              dateEnd: '',
              valueStart: '',
              valueEnd: '',
              tableName: ''
            });

            setIsSubmitted(true);

          } else {
            history.push('/admin/comission/table/list');
          }
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleListTableBank = async (febrabanId) => {
    setListTableBank([]);
    if (!febrabanId) return;

    const result = await listAllTableService(febrabanId);

    if (!result) return;

    setListTableBank(result.resultSet);
  };

  const handleSetName = () => {
    setTableName(
      `FGTS: ${data.nameBank} ${data.tableName ? `- ${data.tableName}` : ``} ${data.dateStart && data.dateEnd
        ? `período de ${dayjs(data.dateStart).format('DD/MM/YYYY')} á ${dayjs(
          data.dateEnd
        ).format('DD/MM/YYYY')}`
        : ``
      }  ${data.valueStart && data.valueEnd
        ? ` e valor de  ${data.valueStart} á ${data.valueEnd}`
        : ``
      } | ${data.valueComission}%`
    );
  };

  useEffect(() => {
    handleSetName();
  }, [data]);

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        bankRef.current?.focus();
      }, 1000);
    }
  }, [isSubmitted]);

  return (
    <>
      <Header titlePage="COMISSIONAMENTO - CADASTRAR TABELA DE COMISSÃO" />
      <Container className="mt--9" fluid>
        <Alert color="secondary">
          <i className="fas fa-exclamation-triangle" /> Os campos com{' '}
          <strong>*</strong> são obrigatórios obrigatórios
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <Title>
              <i className="fas fa-percentage" /> Cadastrar - Tabela de Comissão
            </Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Produto <span className="text-danger">*</span>
                  </Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    value={data.serviceId}
                    onChange={(ev) => {
                      setData({
                        ...data,
                        serviceId: ev.target.value
                      });
                      handleSetName();
                    }}
                    ref={bankRef}
                  >
                    <option value="2" selected>
                      FGTS
                    </option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <InputFebrabanView
                  nameLabel="Financeira"
                  isRequired={true}
                  setFilter={(e) => {
                    setData({
                      ...data,
                      febrabanId: e.febraban,
                      nameBank: e.nameBank
                    });
                    handleSetName();
                    handleListTableBank(e.febraban);
                  }}
                  isClearInput={true}
                />
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    % Banco <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="0,00"
                    value={data.valueComissionBank}
                    onChange={(ev) => {
                      setData({
                        ...data,
                        valueComissionBank: moneyMask(ev.target.value)
                      });
                      handleSetName();
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    % Vendedor <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="0,00"
                    value={data.valueComission}
                    onChange={(ev) => {
                      setData({
                        ...data,
                        valueComission: moneyMask(ev.target.value)
                      });
                      handleSetName();
                    }}
                  />
                </FormGroup>
              </Col>
              {data.febrabanId && listTableBank.length > 0 && (
                <Col md="6">
                  <FormGroup>
                    <Label>Tabela Banco</Label>
                    <select
                      className="form-control"
                      value={data.tableComissionBankId}
                      onChange={(ev) => {
                        let index = ev.nativeEvent.target.selectedIndex;
                        setData({
                          ...data,
                          tableComissionBankId: ev.target.value,
                          tableName: ev.nativeEvent.target[index].text
                        });
                      }}
                    >
                      <option value="">Selecione...</option>
                      {listTableBank.map((tableBank) => (
                        <option key={tableBank.id} value={tableBank.id}>
                          {tableBank.tableName}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Card className="mt-4 mb-4 shadow">
              <CardHeader>
                Base Calculo comissão <span className="text-danger">*</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>Tipo de calculo</Label>
                      <select
                        className="form-control"
                        value={data.typeCalcComission}
                        onChange={(ev) => {
                          setData({
                            ...data,
                            typeCalcComission: ev.target.value
                          });
                          handleSetName();
                        }}
                      >
                        <option value="PERIOD" selected>
                          POR DATA
                        </option>
                        <option value="VALUE">POR VALOR</option>
                        {/* <option value="ALL">DATA E VALOR</option> */}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Período de Validade{' '}
                        <span className="text-danger">*</span>
                      </Label>
                      <InputGroup>
                        <Input
                          id="dateStart"
                          name="dateStart"
                          type="date"
                          className="border"
                          value={data.dateStart}
                          placeholder="--/--/----"
                          onChange={(ev) => {
                            setData({
                              ...data,
                              dateStart: ev.target.value
                            });
                            handleSetName();
                          }}
                        />
                        <Input
                          id="dateEnd"
                          name="dateEnd"
                          type="date"
                          className="border pl-3"
                          value={data.dateEnd}
                          placeholder="--/--/----"
                          onChange={(ev) => {
                            setData({
                              ...data,
                              dateEnd: ev.target.value
                            });
                            handleSetName();
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  {data.typeCalcComission === 'VALUE' && (
                    <>
                      <Col md="3">
                        <FormGroup>
                          <Label>
                            Valor Inicial <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            value={data.valueStart}
                            placeholder="R$ 0,00"
                            onChange={(ev) => {
                              setData({
                                ...data,
                                valueStart: moneyMask(ev.target.value)
                              });
                              handleSetName();
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>
                            Valor Final <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            value={data.valueEnd}
                            placeholder="R$ 0,00"
                            onChange={(ev) => {
                              setData({
                                ...data,
                                valueEnd: moneyMask(ev.target.value)
                              });
                              handleSetName();
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
            <FormGroup>
              <Label>Nome da tabela</Label>
              <Input
                type="text"
                value={tableName}
                onChange={(ev) => {
                  setTableName(ev.target.value);
                }}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default ComissionTableRegisterView;

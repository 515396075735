import imgSafra from './../../../assets/img/banks/074.png';
import imgFacta from './../../../assets/img/banks/149.png';
import imgBmg from './../../../assets/img/banks/318.png';
import imgiCred from './../../../assets/img/banks/329.png';
import imgMercantil from './../../../assets/img/banks/389.png';
import imgQueroMais from './../../../assets/img/banks/465.png';
import imgPAN from './../../../assets/img/banks/623.png';
import imgC6 from './../../../assets/img/banks/626.png';
import imgNovoSaque from './../../../assets/img/banks/274.png';
import imgCrefaz from './../../../assets/img/banks/321.png';
import imgSABEMI from './../../../assets/img/banks/329-2.png';
import imgMASTER from './../../../assets/img/banks/243.png';
import imgUPP from './../../../assets/img/banks/373.png';
import imgUY3 from './../../../assets/img/banks/457.png';
import imgGRANDINOBANK from './../../../assets/img/banks/329-5.png';
import imgLOTUSMAIS from './../../../assets/img/banks/324.png';
import imgV8DIGITAL from './../../../assets/img/banks/329-4.png';
import imgPRATADIGITAL from './../../../assets/img/banks/329-3.png';
import imgVCTEX from './../../../assets/img/banks/329-6.png';
import imgPresencaBank from './../../../assets/img/banks/531.png';
import imgCrefisa from './../../../assets/img/banks/069.png';

export const BankSelectData = (febrabanId) => {
  let bankUsed = null;
  let nameBank = null;

  switch (febrabanId) {
    case '074':
      bankUsed = imgSafra;
      nameBank = 'BANCO SAFRA';
      break;
    case '149':
      bankUsed = imgFacta;
      nameBank = 'FACTA FINANCEIRA';
      break;
    case '318':
      bankUsed = imgBmg;
      nameBank = 'BANCO BMG';
      break;
    case '329':
      bankUsed = imgiCred;
      nameBank = 'iCred';
      break;
    case '389':
      bankUsed = imgMercantil;
      nameBank = 'Banco Mercantil';
      break;
    case '465':
      bankUsed = imgQueroMais;
      nameBank = 'Quero+Crédito';
      break;
    case '623':
      bankUsed = imgPAN;
      nameBank = 'BANCO PAN';
      break;
    case '626':
      bankUsed = imgC6;
      nameBank = 'C6 CONSIG / C6BANK';
      break;
    case '274':
      bankUsed = imgNovoSaque;
      nameBank = 'NOVO SAQUE';
      break;
    case '321':
      bankUsed = imgCrefaz;
      nameBank = 'CREFAZ';
      break;
    case '329-2':
      bankUsed = imgSABEMI;
      nameBank = 'SABEMI';
      break;
    case '243':
      bankUsed = imgMASTER;
      nameBank = 'BANCO MASTER';
      break;
    case '373':
      bankUsed = imgUPP;
      nameBank = 'UP.P';
      break;
    case '457':
      bankUsed = imgUY3;
      nameBank = 'UY3';
      break;
    case '329-5':
      bankUsed = imgGRANDINOBANK;
      nameBank = 'GRANDINOBANK';
      break;
    case '324':
      bankUsed = imgLOTUSMAIS;
      nameBank = 'LOTUS MAIS';
      break;
    case '329-4':
      bankUsed = imgV8DIGITAL;
      nameBank = 'V8 DIGITAL';
      break;
    case '329-3':
      bankUsed = imgPRATADIGITAL;
      nameBank = 'PRATA DIGITAL';
      break;
    case '329-6':
      bankUsed = imgVCTEX;
      nameBank = 'VCTEX';
      break;
    case '531':
      bankUsed = imgPresencaBank;
      nameBank = 'PRESENÇA BANK';
      break;
    case '069':
      bankUsed = imgCrefisa;
      nameBank = 'CREFISA';
      break;
  }

  return {
    img: bankUsed,
    name: nameBank,
    febrabanId: febrabanId
  };
};

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const BankDetailsComponent = ({ proposal }) => {

  return (
    <Card className="shadow mt-3">
      <CardHeader className="border-0">
        <h2 className="m-0">
          <i className="fas fa-piggy-bank" /> Dados Bancários
        </h2>
      </CardHeader>
      <CardBody>
        <div>
          <Row>
            <Col md="3" className="p-3">
              <p className="m-0">Código Banco</p>
              <h2 className="m-0">{proposal.bank}</h2>
            </Col>
            <Col md="3" className="p-3">
              <p className="m-0">Agência</p>
              <h2 className="m-0">{proposal.agencyBank || proposal.agency}</h2>
            </Col>
            <Col md="3" className="p-3">
              <p className="m-0">Conta</p>
              <h2 className="m-0">{proposal.numberAccountBank || proposal.numberAccount}-{proposal.digitAccountBank}</h2>
            </Col>
            <Col md="3" className="p-3">
              <p className="m-0">Tipo de conta</p>
              <h2 className="m-0">
                {proposal.typeAccount && (
                  <>
                    {proposal.typeAccount == '01'
                      ? 'CONTA CORRENTE'
                      : 'CONTA POUPANÇA'}
                  </>
                )}
              </h2>
            </Col>
            <Col md="12" className="p-3">
              <p className="m-0">PIX</p>
              <h2 className="m-0">{proposal.PIX || proposal.pix}</h2>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
};


export default BankDetailsComponent;
function randomHexColor() {
  // Gera uma string aleatória no formato '#RRGGBB'
  return `#${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, '0')}`;
}

export const generateColorRegex = (qtd) => {
  return Array.from({ length: qtd }, randomHexColor);
};
